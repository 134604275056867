
export const LoadYogaData = async (dateOffset) => {
    let yogaClasses = [];
    const today = new Date();
    let date = today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0');

    if (typeof dateOffset !== 'undefined') {
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + dateOffset);
        date = tomorrow.getFullYear() + '-' + (tomorrow.getMonth() + 1).toString().padStart(2, '0') + '-' + tomorrow.getDate().toString().padStart(2, '0');
    }
    return new Promise((resolve, reject) => {
        fetch("https://widgets.mindbodyonline.com/widgets/schedules/152397/load_markup?callback=parseData&options%5Bstart_date%5D="+date+"&_=1675791321258", {
            "credentials": "omit",
            "headers": {
                "User-Agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:109.0) Gecko/20100101 Firefox/109.0",
                "Accept": "*/*",
                "Accept-Language": "en-US,en;q=0.5",
                "Sec-Fetch-Dest": "script",
                "Sec-Fetch-Mode": "no-cors",
                "Sec-Fetch-Site": "cross-site"
            },
            "referrer": "https://www.thespacestudios.com/",
            "method": "GET",
            "mode": "cors"
        }).then((response) => response.text())
            .then((text) => {
                //console.log({text});
                const parseData = (any) => {
                    // Should have 3 params class_sessions, calendar and filters 
                    //console.log(any.class_sessions)//html
                    const parser = new DOMParser();
                    const htmlDoc = parser.parseFromString(any.class_sessions, 'text/html');
                    let classDate = htmlDoc.getElementsByClassName('bw-widget__date')[0]?.innerText;
                    let sessions = htmlDoc.getElementsByClassName('bw-session');
                    for (let i = 0; i < sessions.length; i++) {
                        let session = sessions[i];
                        let sessionId = session.getAttribute('id');
                        let nameValue = session.getElementsByClassName('bw-session__name')[0]?.innerText;
                        let timeValue = session.getElementsByClassName('hc_time')[0]?.innerText;
                        let staffValue = session.getElementsByClassName('bw-session__staff')[0]?.innerText;
                        let titleValue = session.getElementsByClassName('bw-session__full-title')[0]?.innerText;
                        let descValue = session.getElementsByClassName('bw-session__description')[0]?.innerText;
                        //console.log({classDate, timeElem, nameElem, staffElem, titleElem, descElem});
                        yogaClasses.push({
                            id: sessionId,
                            day: classDate,
                            time: timeValue,
                            staff: staffValue,
                            name: nameValue,
                            title: nameValue,
                            description: descValue
                        });
                    }
                    resolve(yogaClasses);
                }
        
                // Should have a parseData callback function
                eval(text);
            });
    });


    
}
